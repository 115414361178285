<template>
  <div class="my-scollList">
    <van-list :model="myloading" :finished="myfinished" :finished-text="finishedText" :error.sync="myerror"
              :error-text="errorText" :loading-text="loadingText" :offset="1500" :immediate-check="false" @load="load"
    >
      <van-row v-if="lineType === 2" class="item-list van-clearfix ">
        <van-col style="padding-left:0.2rem;padding-right:0.1rem" :span="12">
          <div v-for="(item,index) in list" :key="index">
            <slot v-if="index%2===0" :item="item" :index="index" />
          </div>
        </van-col>
        <van-col v-for="(item,index) in list" :key="index" style="padding-right:0.2rem;padding-left:0.1rem" :span="12">
          <div>
            <slot v-if="index%2!==0" :item="item" :index="index" />
          </div>
        </van-col>
      </van-row>
      <template v-if="lineType === 1">
        <div v-for="(item,index) in list" :key="index" class="item-list van-clearfix ">
          <slot :item="item" :index="index" />
        </div>
      </template>
    </van-list>
    <van-button v-show="flag_scroll" type="default" size="small" round plain class="backTop" @click="backTop">
      <van-icon name="arrow-up" size="20" />
    </van-button>
    <!-- empty -->
    <van-empty v-if="empty" class="custom-image" image="https://oss.xiaoyi120.com/shop2.0/home/serpro_empty.png" :description="emptyMsg" />
  </div>
</template>

<script>
export default {
  name: 'MyscrollList',
  props: {
    loading: { type: Boolean, default: false },
    finished: { type: Boolean, default: false },
    error: { type: Boolean, default: false },
    loadingText: { type: String, default: '数据正在赶来的路上...' },
    finishedText: { type: String, default: '数据全部到齐啦...' },
    errorText: { type: String, default: '哎呀数据走丢了，点击重新上路赶来...' },
    list: { type: Array, default: () => [] },
    lineType: { type: Number, default: 2 }
  },
  data() {
    return {
      myloading: this.loading,
      myfinished: this.finished,
      myerror: this.error,
      flag_scroll: false,
      empty: false,
      emptyMsg: '无数据',
      emptyImage: 'error'
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll, true)
  },
  methods: {
    // 初始化组件
    load_status_clear() {
      console.log('初始化列表')
      this.empty = false
      this.myfinished = false
      this.myloading = false
      this.flag_scroll = false
      this.error = false
    },
    // 加载列表
    load() {
      console.log('加载')
      this.$emit('myload')
    },
    // 加载事件
    load_loading(loading) {
      console.log('加载事件', loading)
      this.myloading = loading
    },
    // 列表数据是否全部加载完事件
    load_finished(finished) {
      console.log('列表数据是否全部加载完事件')
      this.myfinished = finished
    },
    // 加载是否出错事件
    load_error(error) {
      console.log('加载是否出错事件')
      this.myerror = error
    },
    // 返回顶部
    backTop() {
      window.scrollTo({
        top: 0, // 滚动到顶部
        behavior: 'smooth' // 平滑滚动
      })
    },
    // 返回按钮显示事件
    handleScroll(env) {
      const scrollTop = document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset
      this.flag_scroll = scrollTop > 300
    },
    // 数据为空
    load_empty(type, empty) {
      console.log('加载是否为空', empty)
      if (type === 'ser') {
        this.emptyMsg = '找不到您要的数据呢..'
        this.emptyImage = 'search'
      }
      if (type === 'list') {
        this.emptyMsg = '找不到您要的数据呢..'
        this.emptyImage = 'https://oss.xiaoyi120.com/shop2.0/order/order_list_empty.png'
      }
      this.empty = empty
    }
  }
}
</script>
<style lang="scss">
.my-scollList {
  .van-list {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    .van-list__finished-text {
      width: 100%;
    }
  }
  .backTop {
    position: fixed;
    bottom: 4rem;
    right: 0.3rem;
    height: 0.8rem;
    width: 0.8rem;
    padding-top: 0.1rem;
  }
  .van-empty {
    padding: 0;
    background: #fff;
  }

}
</style>
